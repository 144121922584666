<template>
<svg width="110px" height="110px" viewBox="0 0 110 110" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>画板</title>
    <defs>
        <path d="M41.9598811,80.2957554 C67.9109815,79.3082834 80.4844863,60.4483466 80.4844863,39.2621011 C80.4844863,18.0758557 59.429017,0 38.184813,0 C16.940609,0 0,17.6251728 0,38.8114183 C0,59.9976637 16.0087807,81.2832275 41.9598811,80.2957554 Z" id="path-1"></path>
        <path d="M4.66706266,9.65404033 C6.3243004,9.62191312 6.96149123,7.60048622 6.96149123,5.30591143 C6.96149123,3.01133663 5.02753196,0.353100789 3.24434049,0.473453648 C1.46114902,0.593806507 0.40637631,2.95020388 0.852404028,5.4363415 C1.29843175,7.92247911 3.00982491,9.68616754 4.66706266,9.65404033 Z" id="path-3"></path>
        <polygon id="path-5" points="0 0 8.37915263 0 8.37915263 8.01385462 0 8.01385462"></polygon>
    </defs>
    <g id="画板" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M55.3942585,19.6597759 C66.453032,19.6597759 77.4672084,24.4390035 85.6141912,32.0009452 C93.5044742,39.3246209 98.6939318,49.2448019 98.6939318,59.921877 C98.6939318,71.3816762 95.0802445,82.1649981 87.7766886,89.9442236 C81.2460449,96.9001982 71.7600251,101.477163 59.2073504,101.954808 C46.6545479,102.432458 36.3748702,97.8201677 29.0140494,90.5427692 C20.7853651,82.4073431 16.2094455,70.9261314 16.2094455,59.4711942 C16.2094455,48.5557036 20.5945742,38.5621412 27.7208443,31.3189305 C34.7858477,24.1379917 44.5435898,19.6597759 55.3942585,19.6597759 Z" id="蒙版备份" stroke="#DBF2A0" stroke-width="2" fill="#DBF2A0"></path>
        <g id="编组-2" transform="translate(17.2094, 20.6598)">
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
            </mask>
            <path stroke="#DBF2A0" stroke-width="2" d="M38.184813,-1 C49.2435864,-1 60.2577629,3.77922763 68.4047456,11.3411692 C76.2950287,18.664845 81.4844863,28.585026 81.4844863,39.2621011 C81.4844863,50.7219003 77.870799,61.5052222 70.567243,69.2844477 C64.0365994,76.2404223 54.5505795,80.8173872 41.9979049,81.2950323 C29.4451024,81.7726822 19.1654247,77.1603918 11.8046038,69.8829933 C3.57591954,61.7475672 -1,50.2663555 -1,38.8114183 C-1,27.8959276 3.38512869,17.9023653 10.5113987,10.6591545 C17.5764022,3.47821579 27.3341443,-1 38.184813,-1 Z"></path>
            <path d="M31.2119023,18.7595678 C40.6274616,12.5102544 50.8613792,9.74139342 61.9122414,10.4476917 C64.0651191,10.5852895 66.1774302,10.9461905 68.2010318,11.5199526 C76.8175341,19.1101266 82.2679581,29.257349 82.2679581,40.1990788 C82.2679581,49.382731 79.9472004,58.1345142 75.2654745,65.2509074 C70.9221451,71.8529256 64.5493862,77.055349 56.0938015,79.8968125 C53.0090858,80.1102609 51.7048837,79.3778988 50.1384821,78.4795161 C49.1639511,77.9205905 48.0698314,77.2874959 46.2491553,76.8441778 C46.0514635,76.7960416 45.8603502,76.7538305 45.6753336,76.7168147 C45.6293777,76.5296378 45.5789711,76.3395705 45.5239767,76.1464682 C43.9432262,70.5959623 40.4619097,69.1737531 36.034163,67.2484176 C33.3787855,66.0937688 30.3880966,64.7880644 27.2031333,62.5284168 C15.2648191,52.5394372 22.6658435,41.2680206 25.0443023,38.2270157 C23.2102551,36.5961819 18.4010449,32.1686061 15.4254161,28.0363384 C14.3787244,26.5827934 13.557599,25.1597015 13.1331912,23.8977864 C12.6840886,22.5624448 12.6788043,21.3877311 13.1278345,20.4538983 C13.599299,19.4734097 14.3920581,18.6963693 15.5341988,18.1494851 C16.5432296,17.6663371 17.8448582,17.3629509 19.4469664,17.2659048 C22.3315062,17.0911766 26.2531643,17.5813385 31.2119023,18.7595678 Z" id="形状结合" stroke="#DBF2A0" stroke-width="2" fill="#FFFFFF"></path>
            <path d="M41.7905545,17.0596232 C45.4684888,15.5922958 47.949958,14.8586321 49.2349621,14.8586321 C51.1624682,14.8586321 64.2630223,3.96895273 66.053663,5.1830244 C67.8443038,6.39709606 71.1999966,16.2940089 72.014885,16.8414109 C74.3808305,18.4296509 79.8973195,27.4711038 80.4846962,33.8402241 C72.9925124,43.4652022 64.0239893,38.7943557 54.0818113,37.504643 C47.4536926,36.6448346 43.356607,29.829828 41.7905545,17.0596232 Z" id="路径-3" fill="#FFC622"></path>
            <path d="M38.1128534,75.1673027 C37.9641117,65.6633217 30.5941919,64.9560507 23.71102,56.6491075 C13.9130944,42.6917632 28.5181528,33.5086617 28.5181528,33.5086617 C28.5181528,33.5086617 17.5793745,17.6270309 20.9933604,13.6551603 C23.269351,11.0072465 28.9541955,12.1673134 38.047894,17.1353608 C48.6849445,13.469547 59.2113187,13.3950441 69.6270165,16.911852" id="路径" stroke="#000000" stroke-width="5" transform="translate(44.9778, 43.7208) rotate(-15) translate(-44.9778, -43.7208)"></path>
        </g>
        <g id="椭圆形备份-5" transform="translate(57.7658, 59.9219) rotate(-4) translate(-57.7658, -59.9219)translate(53.91, 54.8599)">
            <mask id="mask-4" fill="white">
                <use xlink:href="#path-3" transform="translate(3.8559, 5.062) rotate(-10) translate(-3.8559, -5.062)"></use>
            </mask>
            <path stroke="#000000" stroke-width="3" d="M3.14333087,-1.02314151 C4.42946248,-1.10994633 5.8593295,-0.362524859 6.92819472,1.02704646 C7.8489361,2.22405034 8.46149123,3.82897984 8.46149123,5.30591143 C8.46149123,6.81552802 8.19788102,8.21447156 7.67750222,9.20063174 C7.36374983,9.79521807 6.95693974,10.2625213 6.47767619,10.5908274 C5.97286056,10.9366373 5.38311628,11.1404408 4.69613619,11.1537586 C3.47215349,11.1774867 2.18835002,10.5965761 1.15868135,9.45811708 C0.318116545,8.52874186 -0.350112512,7.22798916 -0.624023649,5.70122131 C-0.911230402,4.10034432 -0.684644755,2.5485713 -0.123196709,1.38365588 C0.206972203,0.698607884 0.65365641,0.137917896 1.17567017,-0.266340079 C1.74696194,-0.708759946 2.41054063,-0.973683329 3.14333087,-1.02314151 Z"></path>
            <path d="M8.09917245,9.79270531 C10.1895386,9.75218147 10.9932628,7.20244329 10.9932628,4.30816848 C10.9932628,1.41389366 8.55385243,-1.93908705 6.30461385,-1.7872793 C4.05537527,-1.63547154 2.72493178,1.33678353 3.28753135,4.47268718 C3.85013092,7.60859083 6.0088063,9.83322914 8.09917245,9.79270531 Z" id="椭圆形备份-2" fill="#342211" mask="url(#mask-4)" transform="translate(7.076, 4.0005) rotate(5) translate(-7.076, -4.0005)"></path>
        </g>
        <g id="编组-14" transform="translate(74.3911, 66.7831) rotate(-15) translate(-74.3911, -66.7831)translate(65.7245, 58.1854)">
            <path d="M9.94485775,1.93215578 C10.2916333,2.40187833 9.41085415,3.6710073 8.42572407,3.9134607 C7.38584108,4.16938951 6.49860647,3.31377357 6.49860647,2.63312772 C6.49860647,1.92826371 9.7755266,1.7027894 9.94485775,1.93215578 Z" id="路径-14备份" fill="#000000" transform="translate(8.2602, 2.9028) rotate(-5) translate(-8.2602, -2.9028)"></path>
            <path d="M7.51507189,3.34481557 C8.12375085,3.07917293 6.9739101,11.6412319 1.83047599,9.6597627 C1.35928994,9.47824183 0.377070738,8.61614444 0.598249043,8.3546308 C2.48346417,9.47143178 3.46312821,9.21784295 4.52538563,8.75578466 C6.81763835,7.75870584 6.90639293,3.6104582 7.51507189,3.34481557 Z" id="路径-15备份-2" stroke="#000000" stroke-width="1.2" fill="#000000" transform="translate(4.1147, 6.6482) rotate(11) translate(-4.1147, -6.6482)"></path>
            <path d="M15.1467971,0.927649202 C15.7978047,0.758413777 16.0267623,10.2172652 10.8833282,8.23579598 C10.4121422,8.0542751 9.42992297,7.19217771 9.65110128,6.93066408 C11.5363164,8.04746505 12.5175184,8.13732984 13.3676148,7.19320206 C15.3376504,5.00525586 14.4957895,1.09688463 15.1467971,0.927649202 Z" id="路径-15备份-3" stroke="#000000" stroke-width="1.2" fill="#000000" transform="translate(12.5526, 4.7171) scale(-1, 1) rotate(48) translate(-12.5526, -4.7171)"></path>
            <path d="M6.70181288,5.11667649 L11.8474308,7.55395464 C12.0338479,8.56154977 12.1501866,9.17109674 12.1980141,9.38225301 C12.6685917,11.4598312 12.825503,13.1157449 12.697668,14.349519 C12.5318746,15.9496413 11.8937889,16.9141032 10.9803923,17.4072847 C10.3516812,17.7467524 9.69998144,17.7914498 9.02502319,17.5375044 C8.51062026,17.343966 7.96118859,16.9470055 7.40802401,16.2964628 C6.4856337,15.2116965 5.4849757,13.3389393 4.44014873,10.6550786 L6.70181288,5.11667649 Z" id="路径-16备份" stroke="#000000" stroke-width="2" fill="#000000" transform="translate(8.6295, 11.584) rotate(11) translate(-8.6295, -11.584)"></path>
            <path d="M7.70038183,7.03556507 L10.3807703,8.79706717 C11.1718627,12.7030352 11.1143412,14.8929691 10.2082059,15.3668689 C8.84900286,16.0777186 5.99231174,10.7080017 6.40373838,9.69985992 C6.67802282,9.0277654 7.1102373,8.13966712 7.70038183,7.03556507 Z" id="路径-17备份" fill="#FFC859" transform="translate(8.6487, 11.2335) rotate(11) translate(-8.6487, -11.2335)"></path>
        </g>
        <path d="M77.40439,50.6214863 C79.4310347,49.2675084 80.9610662,48.6305225 81.9944845,48.7105284 C82.8776593,48.7789027 84.180934,49.2755034 85.0624797,49.8259218" id="路径-20" stroke="#000000" stroke-width="3" stroke-linecap="round" transform="translate(81.2334, 49.6627) rotate(-15) translate(-81.2334, -49.6627)"></path>
        <path d="M57.7337603,72.6869851 C53.6403316,71.7195374 49.5469037,70.75209 45.4534751,69.7846424" id="路径-23" stroke="#000000" stroke-width="2" stroke-linecap="round" transform="translate(51.5936, 71.2358) rotate(-15) translate(-51.5936, -71.2358)"></path>
        <path d="M56.8805848,76.7975302 C53.8816378,76.2886069 50.8826909,75.7796835 47.883744,75.2707602" id="路径-24" stroke="#000000" stroke-width="2" stroke-linecap="round" transform="translate(52.3822, 76.0341) rotate(-31) translate(-52.3822, -76.0341)"></path>
        <path d="M90.2614036,59.966646 C93.2032098,61.0713677 96.145016,62.1760894 99.0868221,63.280811" id="路径-26" stroke="#000000" stroke-width="2" stroke-linecap="round" transform="translate(94.6741, 61.6237) rotate(-39) translate(-94.6741, -61.6237)"></path>
        <path d="M90.2614036,64.966646 C93.2032098,66.0713677 96.145016,67.1760894 99.0868221,68.280811" id="路径-26备份" stroke="#000000" stroke-width="2" stroke-linecap="round" transform="translate(94.6741, 66.6237) rotate(-21) translate(-94.6741, -66.6237)"></path>
        <path d="M70.3761113,31.1983656 C81.3023592,26.7605004 87.4035936,25.0750671 88.6798145,26.1420658 C90.6957355,27.8274991 89.0575836,34.2834866 83.7653588,45.5100284" id="路径-19备份-7" stroke="#000000" stroke-width="5" fill="#FFC422" stroke-linecap="round" transform="translate(79.945, 35.6709) rotate(-19) translate(-79.945, -35.6709)"></path>
        <g id="编组" transform="translate(38.2446, 34.164) rotate(-18) translate(-38.2446, -34.164)translate(-0.6889, 14.0465)">
            <path d="M0.23736892,34.8291969 L0,36.6894067 L8.23444088,40.2350091 L8.41416306,38.8074961 L6.22810834,37.8996055 L6.61807156,34.8349919 L8.98045749,34.3704224 L9.16583131,32.9023441 L0.23736892,34.8291969 Z M2.42705395,36.2511632 L2.4451392,36.1256039 L6.07688368,35.4041207 L5.79204098,37.6448718 L2.42705395,36.2511632 Z" id="Fill-1" fill="#231916"></path>
            <polygon id="Fill-2" fill="#231916" points="8.76012867 28.3020064 10.0558333 28.6398372 8.71004798 32.8849221 0.890001951 30.8511809 1.31408947 29.5143363 7.83843092 31.2112119"></polygon>
            <polygon id="Fill-3" fill="#231916" points="11.5927779 23.4064665 12.7856625 23.9187207 10.8196311 27.9893821 3.61983121 24.8943499 4.23855591 23.6127368 10.2454711 26.1955148"></polygon>
            <polygon id="Fill-4" fill="#231916" points="11.07165 18.1355188 12.4698593 16.4667761 16.5963502 18.0982076 16.6355523 18.0506359 14.3291603 14.2467608 15.6872341 12.6255899 21.1540645 17.1990081 20.3028182 18.2148051 15.844976 14.4846195 15.8048405 14.5321913 18.1784361 18.511429 17.2945214 19.5664027 12.9626861 17.9237778 12.923484 17.9713496 17.3822595 21.7006023 16.537547 22.7080043"></polygon>
            <polygon id="Fill-5" fill="#231916" points="25.1603111 12.1276384 26.0127193 13.0573557 21.9982567 15.8089815 16.846888 10.1959652 20.6941265 7.55973328 21.5400195 8.48100722 18.9187285 10.276647 20.1653076 11.6369802 22.5998289 9.96893029 23.4088023 10.8508015 20.9753669 12.5179133 22.3707102 14.038672"></polygon>
            <g transform="translate(23.7885, -0)">
                <path d="M5.48297706,6.64373853 C4.58063151,5.08959099 3.90939897,4.59975538 2.52774515,5.24446548 C1.13604294,5.89457819 1.1993478,6.66805022 2.10370303,8.22309819 C3.022126,9.80245786 3.66723273,10.2490727 5.05893494,9.59986041 C6.44058876,8.95424987 6.40240488,8.22489906 5.48297706,6.64373853 M0.761237936,8.8488992 C-0.668648162,6.39071683 0.0166521323,5.21204989 1.97608846,4.29630943 C3.93451995,3.38146939 5.3965609,3.55885471 6.82544216,6.01793752 C8.27040084,8.50133202 7.58108119,9.65028466 5.62064002,10.5651247 C3.66220854,11.4799647 2.20519178,11.3340946 0.761237936,8.8488992" id="Fill-6" fill="#231916"></path>
                <polygon id="Fill-8" fill="#231916" points="7.12277406 2.0966414 8.59586824 1.78058938 11.1652419 6.71766409 11.2305565 6.70325716 11.295871 1.93366301 12.9287346 1.5824941 15.3684841 5.81543012 15.4337986 5.80102319 15.2368501 0.353402909 16.6888427 0.0418530553 16.7461185 7.09854729 14.9725774 7.47943049 12.5056973 3.27260703 12.4423925 3.28611353 12.3288457 8.04670335 10.5663578 8.42488525"></polygon>
                <path d="M21.6664139,6.64454892 L21.7658929,5.48839281 C22.215056,5.6783842 23.1405129,5.87197731 23.9463939,5.9260033 C25.0044895,5.99803795 25.4425994,5.8008431 25.5018849,5.09850528 C25.557151,4.45649648 25.3190041,4.26200293 24.0860665,3.88832319 C22.5054545,3.4426088 22.0331801,3.00319745 22.1356737,1.79751752 C22.2552496,0.404547509 23.239992,-0.104197194 25.0155427,0.0173612748 C25.8425253,0.0740885604 26.5599805,0.242469551 26.9458387,0.398244478 L26.8513838,1.49407156 C26.4665304,1.33919707 25.6284946,1.15280742 24.9673104,1.10778576 C24.0408487,1.04385501 23.6077631,1.18342214 23.5494824,1.86505 C23.5052695,2.3873012 23.6680534,2.55838349 24.857783,2.90865197 C26.6102224,3.42550057 27.0453178,3.91263488 26.9337806,5.21736245 C26.819229,6.55990821 25.8897528,7.18210748 23.8941422,7.04524165 C22.9907918,6.98311176 22.0854318,6.80212471 21.6664139,6.64454892" id="Fill-10" fill="#231916"></path>
                <path d="M31.0887905,4.69421082 C32.2855539,4.97964811 32.7638573,4.79686019 33.0522462,3.82979504 C33.3235527,2.9203576 33.0080332,2.51696357 31.8213182,2.23332714 L31.0214663,2.04153489 L30.2879337,4.50241856 L31.0887905,4.69421082 Z M29.9231771,0.691785669 L32.2956023,1.25905852 C34.4750984,1.78130972 34.958426,2.58269519 34.490171,4.15305052 C33.9917708,5.82965696 32.9778881,6.23395142 30.9581614,5.75041885 L29.9854771,5.51810711 L29.3443897,7.67284352 L27.943644,7.33878284 L29.9231771,0.691785669 Z" id="Fill-12" fill="#231916"></path>
                <path d="M38.7810351,7.6004487 L39.0523417,4.63892422 L38.9307561,4.59120127 L36.638718,6.74683812 L38.7810351,7.6004487 Z M38.6805512,8.75210264 L35.7504402,7.58514134 L34.2572493,8.98621525 L32.8936826,8.44325409 L38.6373431,3.20543472 L40.4159084,3.91407557 L39.9054501,11.2354971 L38.5016898,10.6763282 L38.6805512,8.75210264 Z" id="Fill-14" fill="#231916"></path>
                <path d="M42.1949761,8.54923506 C43.7765929,6.30715663 45.5069259,6.24412632 47.3930089,7.31294041 C48.0190237,7.66771105 48.4350271,7.98556394 48.7475321,8.33853372 L48.0692657,9.3010967 C47.7557559,8.94722649 47.3608541,8.61856841 46.8011587,8.30071553 C45.5300372,7.58126948 44.5945319,7.742447 43.5083008,9.28128718 C42.3567552,10.9137724 42.6431343,11.6377206 43.8851155,12.3409589 C44.4548593,12.6633139 44.9693369,12.8605088 45.5752549,12.9820672 L44.9090466,13.9284224 C44.431748,13.8455826 43.79468,13.6357817 43.1596216,13.2774093 C41.3006692,12.2239026 40.4767011,10.9840062 42.1949761,8.54923506" id="Fill-16" fill="#231916"></path>
                <g transform="translate(45.6993, 9.4012)">
                    <mask id="mask-6" fill="white">
                        <use xlink:href="#path-5"></use>
                    </mask>
                    <g id="Clip-19"></g>
                    <polygon id="Fill-18" fill="#231916" mask="url(#mask-6)" points="4.47555354 7.13233061 3.66967255 8.01385462 0 5.31975878 4.86342145 0 8.3793536 2.57974084 7.58050648 3.45406139 5.18496997 1.69641597 4.0072985 2.98493574 6.23201236 4.61652052 5.46833461 5.45212244 3.24362075 3.81963723 1.92627663 5.26213106"></polygon>
                </g>
            </g>
        </g>
        <path d="M54.9809536,43.5165713 C56.6397267,44.0695677 60.1608596,33.8266417 59.4811646,33.8266417 C59.0280345,33.8266417 57.9559431,34.0545382 56.2648902,34.5103311 C54.3030837,40.145827 53.8751049,43.1479071 54.9809536,43.5165713 Z" id="路径-13备份-5" fill="#000000" transform="translate(56.9751, 38.6824) rotate(-28) translate(-56.9751, -38.6824)"></path>
        <path d="M58.9218145,39.6997782 C60.0611737,40.079614 62.4797292,33.0440698 62.0128681,33.0440698 C61.7016273,33.0440698 60.9652414,33.2006047 59.8037102,33.5136746 C58.4562069,37.3845197 58.1622417,39.4465543 58.9218145,39.6997782 Z" id="路径-13备份-6" fill="#000000" transform="translate(60.2915, 36.3793) rotate(-28) translate(-60.2915, -36.3793)"></path>
        <path d="M74.0560683,78.2917959 C81.0171262,81.4190142 75.124387,93.0843408 72.4437156,96.7863388 C69.7630442,100.488337 63.9374019,100.160377 61.7109444,99.4116286 C58.3933986,98.2959514 56.2878366,94.894629 55.3942585,89.2076613 C63.1947598,79.8456055 69.4153631,76.2069838 74.0560683,78.2917959 Z" id="路径-28备份" stroke="#000000" stroke-width="4" fill="#FFFFFF"></path>
        <path d="M70.430111,85.2959014 C72.9718236,83.6664149 74.5906862,82.0439516 75.286699,80.4285116" id="路径-29备份" stroke="#000000" stroke-width="3" stroke-linecap="round"></path>
    </g>
</svg>
</template>
