<template>
  <div class="tableBox">
    <section
      class="Handle"
      v-if="isHandleFrom"
    >
      <el-form
        :model="formData"
        ref="form"
        label-width="auto"
        label-position="right"
      >
        <div class="from">
          <el-form-item
            v-for="(item, index) in tableHandlesFrom"
            :key="index"
            :label="item.label"
            :prop="item.prop"
          >
            <el-input
              v-if="item.type === 'input'"
              v-model="formData[item.prop]"
              clearable
              :placeholder="`请输入${item.label}`"
            ></el-input>
            <el-select
              clearable
              v-else-if="item.type === 'select'"
              class="select"
              v-model="formData[item.prop]"
              :placeholder="`所有`"
            >
              <el-option
                v-for="(option, index) in item.options"
                :key="index"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>

            <!-- 其他类型的输入组件可以继续添加 -->
          </el-form-item>
          <el-button
            type="primary"
            @click="onSubmit"
          >确定</el-button>
        </div>

      </el-form>
    </section>

  </div>
</template>
 
<script>
export default {
  name: "SearchTable",
  data () {
    return {
      formData: {},

    };
  },
  props: {

    isHandleFrom: {
      type: Boolean,
      default: false,
    },
    tableHandlesFrom: {
      type: Array,
      default: () => [],
    },

  },
  methods: {
    onSubmit () {
      this.$emit("search", this.formData);

    }

  },
};
</script>
 
<style lang="less" scoped>
.tableBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
}
.Handle {
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(217, 217, 217);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.from {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  /deep/.el-form-item__label-wrap {
    margin-left: 0px !important;
  }
  /deep/.el-form-item {
    width: auto;
    margin-right: 20px;
    margin-bottom: 0px;
  }
  .select {
    width: 150px;
  }
}
</style>