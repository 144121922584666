<!--
 * @Description: 
 * @Author: 高泽峰 Design.Gao
 * @Date: 2024-09-24 13:20:40
 * @LastEditTime: 2024-11-11 15:25:55
 * @LastEditors:  
-->
<template>
  <el-config-provider :size="size" :z-index="zIndex" :locale="locale">

  <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
  </keep-alive> -->
    <router-view ></router-view>
  </el-config-provider>
</template>

<script setup>
import { ElConfigProvider, ElMessage, ElDialog, ElMessageBox } from "element-plus";

import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "dayjs/locale/zh-cn";
const locale = zhCn;
const zIndex = 3000;
const size = "";
</script>

<style lang="less" scoped>
nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}
 
</style>
