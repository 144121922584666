<!--
 * @Author: 界点 5223308+界点@user.noreply.gitee.com
 * @Date: 2024-09-23 20:30:58
 * @LastEditors:  
 * @LastEditTime: 2024-11-11 18:07:38
 * @FilePath: \界点\src\layout\components\logo\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <h1 class="logo">
    <IconLogo class="icon mr-5"></IconLogo>
    <span class="f-20" v-show="!flag">界点</span>
  </h1>
</template>

<script name="Logo">
import { defineComponent } from "vue";
import IconLogo from "@/components/icons/IconLogo.vue";
export default defineComponent({
  components: { IconLogo },
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isCollapse(v, o) {
      this.flag = v;
    },
  },
  data() {
    return {
      flag: false,
    };
  },
  created() {},
  methods: {},
});
</script>

<style scoped>
.icon {
  width: 32px;
  height: 32px;
}
.logo {
  padding: 9px;
  font-size: 20px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 10px;
}
</style>
