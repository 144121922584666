import { defineStore } from "pinia";
import Login from "@/api/Login";
import System from "@/api/System";


const modules = import.meta.glob("@/views/**/*.vue");
export const userStore = defineStore("user", {
  state: () => ({
    token: localStorage.getItem("token") || "",
    userInfo: null,
    menuList: [], //左侧菜单
    addressList: [],
  }),
  actions: {
    // 登录
    async login (parm) {
      try {
        const res = await Login.toLogin(parm);
        console.log(res)
        if (res.code == 200) {
          this.token = res.data.token;
        }
        return res
      } catch (error) {
        console.log(error);
      }
    },

    async getAddressList () {
      try {
        const res = await System.getAddressList();
        if (res.code == 200) {
          console.log(res.data)
          this.addressList = res.data
          // return res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },




    // 获取用户信息
    async getUserInfo () {
      try {
        const res = await Login.userInfo();
        if (res.code == 200) {
          this.userInfo = res.data;
          return res.data.role;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 获取菜单列表
    async getMenuList () {
      try {
        const res = await Login.menuList();
        if (res.code == 200) {
          let router = dealRoute(res.data);
          this.menuList = router;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 退出登录
    logout () {
      this.userInfo = null;
      this.token = "";

      localStorage.removeItem("token");
    },

    // 获取权限路由
    getAccessRoutes (role) {
      let asyncRoutes = this.menuList;
      const accessRoutes = filterRoutes(asyncRoutes, role);
      //将path路径改为  绝对路径
      const fullPathRoutes = getFullPathRoutes(accessRoutes, "");
      //将redirect改为children的第一个
      updateRoutesRedirect(fullPathRoutes);
      this.menuList = fullPathRoutes;
      return fullPathRoutes;
    },
  },
  persist: {
    enabled: true, //开启
    strategies: [
      {
        key: "user",
        storage: localStorage,
        paths: ["token"], //指定字段
      },
      {
        key: "addressList",
        storage: localStorage,
        paths: ["addressList"], //指定字段
      },
    ],
  },
});

//根据角色过滤路由
function filterRoutes (routes, role) {
  const res = [];

  routes.forEach((route) => {
    //解构出来，防止修改源
    const tmp = { ...route };
    if (tmp.children) {
      tmp.children = filterRoutes(tmp.children, role);
    }
    //判断是否有权限，有权限则push
    if (hasPermission(tmp, role)) {
      res.push(tmp);
    }
  });

  return res;
}

//返回是角色
function hasPermission (route, role) {
  if (route?.meta?.role) {
    return route.meta.role.includes(role);
  } else {
    return true;
  }
}

//生成完整路径 讲path路径改成绝对路径
function getFullPathRoutes (routes, parentPath) {
  const res = [];
  routes.forEach((route) => {
    const temp = { ...route };
    temp.path = parentPath + temp.path;
    res.push(temp);
    if (temp.children) {
      temp.children = getFullPathRoutes(
        temp.children,
        temp.path + (temp.path === "/" ? "" : "/")
      );
    }
  });

  return res;
}

//讲完整路由重定向改为children第一项
function updateRoutesRedirect (routes) {
  routes.forEach((route) => {
    if (route.children) {
      route.redirect = route.children[0].path;
      updateRoutesRedirect(route.children);
    }
  });
}

//更具接口返回的路由，动态加载组件
function dealRoute (routes) {
  let res = []; //递归的适合函数内部形成作用域，不影响这个
  routes.forEach((item) => {
    if (item.component) {
      item.component = item.component.replace("@/", "/src/");
      item.component = modules[`${item.component}`];
    }
    res.push(item);
    if (item.children) {
      dealRoute(item.children);
    }
  });

  return res;
}
