import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/layout/index.vue";
 

export const constantRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/home",
    children: [],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/404.vue"),
    meta: {
      title: "404",
    },
  },
];

// 看作是异步获取路由 通过路由拦截来动态把这块追加进去
export const asyncRoutes = [
 
];

//导出最终路由
export const routes = constantRoutes;

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

/**
 * 路由拦截
 * 1.用户登录之后，通过pinia在存储用户角色和token
 * 2.通过路由和角色过滤出对应角色的路由，然后动态添加到路由上面
 * router.beforeEach(async (to, from, next)
 */

import { userStore } from "@/stores/user";

router.beforeEach(async (to, from) => {
  const user = userStore();
  if (user.token) {
    //已登录
    if (to.path === "/login") {
      //已登录直接进入首页
      return { path: "/" };
    } else {
      //校验token是否正确或者过期
      if (user.userInfo) {
        //刷新页面后，这个就没有了，然后动态添加路由，刷新重定向路由
        return true;
      } else {
        //然后动态添加路由，刷新重定向路由
        const role = await user.getUserInfo();
        //根据获取菜单
        await user.getMenuList();
        //根据用户角色role，和菜单生成路由
        const accessRoutes = user.getAccessRoutes(role);
        //添加路由
        accessRoutes.forEach((route) => {
          router.addRoute("Layout", route);
        });
        //触发重定向
        return to.fullPath;
      }
    }
  } else {
    //没登录
    if (to.path === "/login") {
      return true; //放行
    } else {
      return { path: "/login", query: { redirect: to.fullPath } };
    }
  }
});
export default router;
