<template>
  <svg
    viewBox="0 0 48 48"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="arco-icon arco-icon-launch"
    stroke-width="4"
    stroke-linecap="butt"
    stroke-linejoin="arcs"
    filter=""
    data-v-249840b0=""
    style="font-size: 32px"
  >
    <path
      d="M41 26v14a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h14M19.822 28.178 39.899 8.1M41 20V7H28"
    ></path>
  </svg>
</template>
